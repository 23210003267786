:root {
  /* Assign color palette to semantic names */
  --color-primary: #073B4C; /* Example: a dark blue for primary actions */
  --color-secondary: #FF9B53; /* Example: an orange for secondary actions */
  --color-info: #118AB2; /* Example: a lighter blue for informational elements */
  --color-success: #06D6A0; /* Example: a green for success states */
  --color-warning: #FFD166; /* Example: a yellow for warnings */
  --color-danger: #FF595E; /* Example: a red for danger alerts or errors */
  --color-light: #FFEDDF; /* Example: a light peach for background or light elements */
  --color-dark: #303030; /* Example: a dark color for text or dark elements */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* App.css */
.modal-backdrop {
  z-index: 1050 !important;
}

.modal-dialog {
  z-index: 1060 !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.steps-sidebar {
  padding: 20px;
  background-color: #f4f4f4; /* Match your color scheme */
}

.step {
  margin-bottom: 15px;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
}

.step-label {
  font-size: 18px;
  font-weight: bold;
}

.step-info {
  font-size: 14px;
  color: #666; /* Placeholder text color */
}

.activeStep .step-number,
.activeStep .step-label {
  color: var(--color-secondary);
}

.active {
  color: var(--color-primary);
  font-weight: bold; /* Optional: if you want active item to be bold */
}

/* Style adjustments for the list group items */
.d-block .border-0.border-bottom.rounded {
  display: flex;
  align-items: center;
  padding: 1rem; /* Or adjust as needed */
}
.btn-primary {
  background-color: var(--color-primary)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-secondary {
  background-color: var(--color-secondary)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-back {
  background-color: var(--color-info)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-info {
  background-color: var(--color-info)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-success {
  background-color: var(--color-success)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-danger {
  background-color: var(--color-danger)!important;
  color: white !important;
  font-weight: bold !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.badge-success {
  background-color: var(--color-success);
  color: white;
}

.d-block .border-0.border-bottom.rounded:hover {
  background-color: #e9ecef; /* Color for hover state */
}

.d-flexContent {
  display: flex;
  /* min-height: 100vh; */
}

/* Scoped to Quick Actions section */
.quick-actions-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.quick-actions-container .quick-action-btn {
  flex-grow: 1;
  font-weight: 500;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quick-actions-container .quick-action-btn:hover {
  background-color: #e9ecef;
  color: #000;
}


.sidebar {
  flex-shrink: 0; /* Prevent sidebar from shrinking */
  width: 200px; /* Fixed sidebar width */
}
.sidebar .ps-5 {
  padding-left: 2rem !important;
}
.sidebar .d-block {
  color: #333;
}
.sidebar .d-block.active {
  background-color: #f8f9fa;
  font-weight: bold;
}


/* .content-area {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
} */
.dashboard-layout {
  display: flex;
  min-height: 100vh;
}

.content-area {
  background-color: #f7f7f7;
  padding-left: 0;
  flex-grow: 1;
}

.stat-card {
  background-color: #e9ecef;
  border: none;
  border-radius: 8px;
  position: relative;
  padding: 1rem;
}

.stat-icon {
  font-size: 1.5rem;
  color: #6c757d;
  position: absolute;
  top: 10px;
  right: 10px;
}

.stat-card h6 {
  font-size: 1rem;
  color: #333;
}

.stat-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.content-card {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
}



/* .content-card {
  flex-grow: 1;
  margin-bottom: 1rem; 
} */
/* Custom checkbox style */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

input[type="checkbox"]:checked {
  background-color: #007bff; /* Change to primary color */
  border-color: #007bff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='2' d='M4 8l4 4 4-8'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}


.table-header {
  background-color: #0D2D3C; /* Darker background for headers */
  color: #ffffff; /* White text for readability */
  font-weight: bold;
}

table th.custom-th {
  color: white !important;
  font-weight: bold !important;
  background-color: #0D2D3C !important; /* Use !important if necessary */
  text-align: left !important;
  padding: 10px !important;
}

/* Custom styles for rows to keep consistent branding */
.table-row {
  background-color: #f8f9fa; /* Light gray background for rows */
  color: #212529; /* Dark text for contrast */
}

/* Hover effect for rows */
.table-row:hover {
  background-color: #e9ecef; /* Slightly darker gray on hover */
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown-menu {
  min-width: 180px; /* Adjust width */
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  z-index: 1050; /* Ensure it's above other elements */
}

.custom-dropdown-menu .dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
}

.custom-dropdown-menu .dropdown-item:hover {
  background-color: #f5f5f5;
  color: #007bff;
}

.custom-dropdown-menu .dropdown-header {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  padding: 10px 15px;
}